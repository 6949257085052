<template>
  <component :is="chips ? 'v-chip-group' : 'div'">
    <template v-for="(specialty, index) in displayable">
      <template v-if="chips">
        <v-chip :key="index" class="mr-1">{{ specialty.name }}</v-chip>
      </template>
      <template v-else>
        <span :key="index">{{ specialty.name }}</span>
        <span v-if="index + 1 < displayable.length" :key="'splitter-'+index"> {{ splitter }} </span>
      </template>
    </template>
    <template v-if="overflow.length > 0">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-chip v-if="chips" v-bind="attrs" v-on="on">
            +{{ overflow.length }} spécialités
          </v-chip>
          <span v-else v-bind="attrs" class="ml-1" v-on="on">
            et {{ overflow.length }} autres</span>
        </template>
        <span>
          <template v-for="(specialty, index) in overflow">
            <span :key="index">{{ specialty.name }}</span>
            <span v-if="index + 1 < overflow.length" :key="'splitter-'+index"> {{ splitter }} </span>
          </template>
        </span>
      </v-tooltip>
    </template>
  </component>
</template>

<script>
  import {clone} from '@/utils/array'

  export default {
    name: 'ProSpecialtiesDisplayer',
    props: {
      text: Boolean,
      chips: Boolean,
      specialties: Array,
      splitter: {
        type: String,
        default: '•'
      },
      max: {
        type: Number,
        default: 4
      }
    },
    data() {
      return {
        displayable: [],
        overflow: []
      }
    },
    created() {
      this.displayable = clone(this.specialties)
      if(this.displayable.length > this.max) {
        this.overflow = this.displayable.splice(this.max, (this.displayable.length - this.max))
      }
    }
  }
</script>
