<template>
  <v-col cols="1" class="title-container-modal px-2 primary">
    <div class="text-h6 font-weight-thin title-modal text-uppercase white--text font-alt" :class="$vuetify.breakpoint.mdAndDown ? 'd-none' : 'd-flex justify-center'">
      {{ title }}
    </div>
  </v-col>
</template>

<script>
  export default {
    name: 'ModalsSidebar',
    props: {
      title: String
    }
  }
</script>

<style scoped lang="scss">
  .title-modal {
    writing-mode: vertical-lr;
    transform: rotateZ(180deg);
    font-family: 'Raleway', sans-serif !important;
  }
</style>
