<template>
  <v-card class="p-rel">
    <v-btn x-small fab dark color="primary" class="close-modal" @click="$emit('close')">
      <v-icon small color="white">$close</v-icon>
    </v-btn>
    <v-toolbar class="primary white--text d-block d-md-none" max-height="56">
      <v-toolbar-title>Inviter des utilisateurs</v-toolbar-title>
    </v-toolbar>
    <v-row class="ma-0 row-dialog">
      <modals-sidebar title="Inviter des utilisateurs" class="d-none d-md-block" />
      <v-col cols="12" md="11" class="pt-2">
        <form-category title="utilisateurs" subtitle="Liste d'utilisateurs à inviter dans ce groupe" class="mt-3" />
        <skeleton-list v-if="loading" :small="items.length > 0" />
        <template v-else>
          <empty-list v-if="items.length === 0" title="Aucune suggestion"
                      subtitle="Il n'y a pas d'autres utilisateurs à inviter au groupe"
          />
          <template v-else>
            <v-list class="pa-0 " three-line>
              <template v-for="(item, index) in items">
                <template v-if="!guestsUid.includes(item)">
                  <user-list-item :key="item" :user="user(item)" @click="guestsUid.push(item)" />
                  <v-divider v-if="index + 1 < items.length" :key="'divider-'+index" class="my-0" />
                </template>
              </template>
            </v-list>
            <users-chip-group v-model="guestsUid" />
            <div class="d-flex justify-center mt-5">
              <v-btn color="primary" x-small :loading="submitting" :disabled="submitting || loading" @click="submit">
                Inviter ces utilisateurs
              </v-btn>
            </div>
          </template>
        </template>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  import {Professional, Team} from '@/models'
  import UsersChipGroup from '@/modules/core/layout/UsersChipGroup'
  import {listInvitable} from '@/modules/teams/api/invitations'
  import SkeletonList from '@/modules/core/layout/SkeletonList'
  import UserListItem from '@/modules/core/layout/UserListItem'
  import ModalsSidebar from '@/modules/core/modals/ModalsSidebar'
  import {column} from '@/utils/array'
  import EmptyList from '@/modules/core/layout/EmptyList'
  import FormCategory from '@/modules/core/layout/FormCategory'
  import {ITEM_PROJECTIONS} from '@/models/User/Professional'

  export default {
    name: 'TeamInvite',
    components: {EmptyList, ModalsSidebar, UserListItem, SkeletonList, UsersChipGroup, FormCategory},
    props: {
      uid: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        loading: true,
        guestsUid: [],
        items: [],
        submitting: false
      }
    },
    computed: {
      team() {
        return Team.get(this.uid)
      },
      user() {
        return uid => Professional.get(uid)
      }
    },
    async created() {
      this.loading = true
      try {
        let {data} = (await listInvitable(this.uid, ITEM_PROJECTIONS))
        Professional.save(data)
        this.items = column(data, 'uid')
      } finally {
        this.loading = false
      }
    },
    methods: {
      async submit() {
        try {
          this.submitting = true
          await this.team.inviteList(this.guestsUid)

          this.$emit('close')
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'success',
            title: 'Professionnels invités',
            subtitle: 'Les professionnels sélectionnés ont été invités.'
          })
        } catch (e) {
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue',
            subtitle: 'Les professionnels sélectionnés n\'ont pas pu être invités.'
          })
          throw e
        } finally {
          this.submitting = false
        }
      }
    }
  }
</script>
