<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-list v-if="list" two-line>
    <v-list-item>
      <v-list-item-avatar class="mr-3">
        <v-avatar tile width="40" height="40" class="p-2">
          <v-icon class="secondary--text" x-large>mdi mdi-home</v-icon>
        </v-avatar>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-if="!cityOnly">{{ getAddress1 }}</v-list-item-title>
        <v-list-item-subtitle v-if="haveAddress1">{{ city }} {{ zipCode }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
  <span v-else-if="inline">{{ localAddress }}</span>
  <v-list-item-subtitle v-else class="haveAddress" :class="color">{{ localAddress }}</v-list-item-subtitle>
</template>

<script>
  import {empty} from 'esyn-utils/src/utils/variables'

  export default {
    name: 'AddressDisplayer',
    props: {
      list: Boolean,
      inline: Boolean,
      address: {
        type: Object,
        default: null
      },
      cityOnly: Boolean,
      color: {
        type: String,
        default: null
      }
    },

    data() {
      return {
        haveAddress1: true
      }
    },

    computed: {
      getAddress1() {
        const _emptyAddress = 'Adresse non renseignée'
        if (this.address && !this.address.address1) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.haveAddress1 = false
          return _emptyAddress
        }
        return this.address.address1
      },

      zipCode() {
        const _emptyZipCode = 'Non renseignée'
        let zipCode = this.address.zip_code || this.address.zipCode
        if (empty(zipCode) || empty(zipCode.name) || zipCode.name === '00000') {
          return _emptyZipCode
        }
        return zipCode.name
      },

      city() {
        const _emptyCity = ' Ville non renseignée'
        if (empty(this.address.city) || empty(this.address.city.name) || this.address.city.name === 'Inconnu') {
          return _emptyCity
        }
        return this.address.city.name
      },

      localAddress() {
        const _emptyAddress = 'Adresse non renseignée'

        if (empty(this.address)) {
          return _emptyAddress
        }

        if (!this.address.address1 && !this.cityOnly) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.haveAddress1 = false
          return _emptyAddress
        }

        if (this.cityOnly) {
          if (typeof this.address.city !== 'object') {
            return (this.address.city && this.address.city.name !== 'Inconnu' ? this.address.city : _emptyAddress)
          }
          return empty(this.address.city) ? _emptyAddress : (this.address.city.name === 'Inconnu' ? 'Non renseignée' : this.address.city.name)
        }

        let zipCode = this.address.zip_code || this.address.zipCode

        if (empty(this.address.city) || empty(zipCode) || empty(this.address.city.name) || empty(zipCode.name) || empty(this.address.address1)) {
          return _emptyAddress
        }

        return [this.address.address1, zipCode.name, this.address.city.name].join(' ')

      }
    }
  }
</script>
