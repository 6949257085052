<template>
  <v-list-item transition="slide-y-transition" @click.stop="onClick">
    <v-list-item-avatar size="50">
      <user-avatar-img :user="user" />
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title>{{ user.full_name }}</v-list-item-title>
      <template v-if="user.type ==='user:professional'">
        <v-list-item-subtitle>
          <span class="secondary--text text--darken-1 font-weight-medium">{{ getJob(user.job) }}</span>
          <template v-if="user.main_address">
            à <address-displayer city-only inline :address="getAddress(user.main_address)" />
          </template>
        </v-list-item-subtitle>
        <v-list-item-subtitle class="fs-80">
          <pro-specialties-displayer text :max="3" :specialties="user.specialties" />
        </v-list-item-subtitle>
      </template>
      <template v-else-if="user.type === 'user:patient'">
        <v-list-item-subtitle class="primary--text">
          Patient
        </v-list-item-subtitle>
        <address-displayer city-only :address="user.main_address" />
      </template>
    </v-list-item-content>

    <v-list-item-action class="align-self-center flex-row">
      <slot name="actions">
        <template v-if="showButtons">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: onAction }">
              <v-btn icon :disabled="!user.phone" v-on="onAction" @click.stop="call(user.phone)">
                <v-icon color="secondary">far fa-phone</v-icon>
              </v-btn>
            </template>
            <span>Appeler {{ user.full_name }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on: onAction }">
              <v-btn icon :disabled="!user.email" v-on="onAction" @click.stop="sendmail(user.email)">
                <v-icon color="secondary">far fa-at</v-icon>
              </v-btn>
            </template>
            <span>Envoyer un e-mail</span>
          </v-tooltip>
        </template>
      </slot>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
  import AddressDisplayer from '@/modules/core/components/AddressDisplayer'
  import {Person} from '@/models'
  import UserAvatarImg from '@/modules/core/layout/UserAvatarImg'
  import conditionalData from '@/mixins/conditionalData'
  import ProSpecialtiesDisplayer from '@/modules/core/layout/ProSpecialtiesDisplayer'
  import contactMethods from '@/mixins/contactMethods'

  export default {
    name: 'UserListItem',
    components: {ProSpecialtiesDisplayer, UserAvatarImg, AddressDisplayer},
    mixins: [conditionalData,contactMethods],
    props: {
      user: Person,
      showButtons: Boolean,
      goToProfile: Boolean
    },
    methods: {
      onClick() {
        this.$emit('click')
        if(this.goToProfile) {
          if(this.user.type ==='user:professional'){
            this.$router.push({name: 'professional-profile', params: {uid: this.user.uid}})
          }else {
            this.$router.push({name: 'patient-profile', params: {uid: this.user.uid}})
          }
        }
      }
    }
  }
</script>
