import {empty} from 'esyn-utils/src/utils/variables'

export default {
  methods: {
    getJob(job) {
      if (empty(job)) {
        return null
      }

      if (typeof job === 'object') {
        return job.name
      }

      return job
    },

    getAddress(address) {
      if (empty(address) || empty(address.city) || empty(address.zip_code)) {
        return null
      }

      let city = typeof address.city === 'object' ? address.city.name : address.city
      let zipCode = typeof address.zip_code === 'object' ? address.zip_code.name : address.zip_code

      return {
        address1: address.address1,
        city: {name: city},
        zip_code: {name: zipCode}
      }
    }
  }
}
