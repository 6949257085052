<template>
  <v-chip-group multiple column>
    <v-chip v-for="uid in localValue" :key="uid" close pill @click:close="remove(uid)">
      <user-avatar left :user="user(uid)" />
      {{ user(uid).full_name }}
    </v-chip>
  </v-chip-group>
</template>

<script>
  import {removeItem} from '@/utils/array'
  import {Person} from '@/models'
  import UserAvatar from '@/modules/core/layout/UserAvatar'

  export default {
    name: 'UsersChipGroup',
    components: {UserAvatar},
    props: {
      value: Array // array of user uid
    },
    computed: {
      localValue:{
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      user() {
        return uid => Person.get(uid)
      }
    },
    methods:{
      remove(uid) {
        removeItem(this.localValue, uid)
      }
    }
  }
</script>
